import React from 'react'
import { graphql } from 'gatsby'
import { isEmpty } from 'lodash'
import Layout from '../components/layout'
import ThumbModule from '../components/ThumbModule'
import InfiniteScroll from 'react-infinite-scroller'
import Fade from 'react-reveal/Fade'

export default class WorkPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      limit: 9,
    }
  }

  loadFunc = () => {
    this.setState({ limit: this.state.limit + 3 })
  }

  render() {
    const { data, location } = this.props

    const { limit } = this.state

    const allMarkdownRemark = data.allMarkdownRemark
    const edges = allMarkdownRemark.edges
    const limitedEdges = edges.slice(0, limit)
    return (
      <Layout type={'home'} location={location} title="Projects">
        <div className="mb4 pt4">
          <div className="mw7-m mw8-l center ph3">
            <div className="cf mh-2">
              <InfiniteScroll
                pageStart={0}
                loadMore={this.loadFunc}
                hasMore={limit < edges.length ? true : false}
                loader={
                  <div className="loader" key={0}>
                    Loading ...
                  </div>
                }
                threshold={100}
              >
                {limitedEdges.map((work, i) => {
                  const frontmatter = work.node.frontmatter
                  if (frontmatter.layout === 'project') {
                    return (
                      <Fade key={i}>
                        <div className="fl w-100 w-50-m w-third-l ph2 mb3">
                          <a href={frontmatter.path}>
                            <div className="relative aspect-ratio aspect-ratio--1x1 shadow">
                              <div className="absolute top-0 left-0 pa3 white z-999">
                                {frontmatter.client && (
                                  <span className="child o-60">
                                    {!isEmpty(frontmatter.client) && frontmatter.client[0].name}
                                  </span>
                                )}
                                <p className="ma0 child">{frontmatter.title}</p>
                              </div>
                              <div className="w-100 h-100">
                                {frontmatter.thumb && (
                                  <ThumbModule
                                    index={i}
                                    title={frontmatter.title}
                                    thumb={frontmatter.thumb}
                                    thumbPoster={frontmatter.thumb_poster}
                                  />
                                )}
                              </div>
                            </div>
                          </a>
                        </div>
                      </Fade>
                    )
                  }

                  return null
                })}
              </InfiniteScroll>
              <div className="fixed f4 right-0 mt3 mt4-ns ph4 ph5-l bottom-2">
                <p className="mb0 mt0 pa2 pointer hover-gray back-to-top dn">↑</p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___order] }
      filter: { frontmatter: { layout: { eq: "project" } } }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            client {
              name
              logo
            }
            thumb
            thumb_poster
            layout
          }
        }
      }
    }
  }
`
